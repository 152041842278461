import React from 'react';
import { graphql } from 'gatsby';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Home from 'templates/Home';
import SEO from 'components/SEO';

const HomePage = ({
  data: {
    ateliware: {
      page,
      testimonials,
      headerMenu,
      footerMenu,
      awards,
      numbers,
      technologies,
      packages,
      servicesV23,
      customers,
      floatingActionButtons
    }
  },
  pageContext
}) => {
  const { seo } = page;
  const { locale } = pageContext;
  console.log('FLOATING ACTION', floatingActionButtons);

  return (
    <React.Fragment>
      <SEO
        meta_title={
          locale === 'pt'
            ? 'Transformamos ideias, produtos, desafios e negócios'
            : seo?.metaTitle
        }
        meta_description={
          locale === 'pt'
            ? 'Mais do que um provedor de tecnologia, transformamos e agilizamos negócios. Somos um atelier de software que desenvolve produtos e soluções digitais à medida que marcam a diferença desde a primeira entrega.'
            : seo?.metaDescription
        }
        og_title={
          locale === 'pt'
            ? 'Transformamos ideias, produtos, desafios e negócios'
            : seo?.ogTitle
        }
        og_description={
          locale === 'pt'
            ? 'Mais do que um provedor de tecnologia, transformamos e agilizamos negócios. Somos um atelier de software que desenvolve produtos e soluções digitais à medida que marcam a diferença desde a primeira entrega.'
            : seo?.ogDescription
        }
        og_type={seo?.ogType}
        og_image={seo?.ogImage?.url}
        lang={locale}
      />
      <Header menu={headerMenu} pageContext={pageContext} />
      <Home
        page={page}
        testimonials={testimonials}
        pageContext={pageContext}
        menu={headerMenu}
        awards={awards}
        numbers={numbers}
        technologies={technologies}
        packages={packages}
        services={servicesV23}
        customers={customers}
        chatDescription={
          Array.isArray(floatingActionButtons) &&
          floatingActionButtons.length > 0
            ? floatingActionButtons?.[0]?.title
            : ''
        }
      />
      <Footer
        footerMenu={footerMenu}
        pageContext={pageContext}
        privatePolicyTitle={page.contactForm.privacyPolicyTitle}
      />
    </React.Fragment>
  );
};

export const homeQuery = graphql`
  query HomePageQuery($idPage: ID!, $locale: [GCMS_Locale!]!) {
    ateliware {
      page(where: { id: $idPage }, locales: $locale) {
        id
        locale
        seo {
          metaTitle
          metaDescription
          ogTitle
          ogDescription
          ogType
          ogImage {
            url
            fileName
          }
        }
        slug
        title
        description
        contentTitle
        contentSubtitle
        content
        callToAction
        contentImage(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
        }
        content1Title
        content1Subtitle
        content1
        callToAction1
        content1Image(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
        }
        content2Title
        content2Subtitle
        content2
        callToAction2
        callToAction3
        content2Image(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
        }
        content3Title
        content3Subtitle
        content3
        content3Image(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
        }
        content5
        content5Subtitle
        content4Subtitle
        content4Title
        content6Subtitle
        content6Title
        content6
        content6FeaturedImage(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
        }
        content8Subtitle
        slider {
          singleSlides {
            title
            subtitle
            description {
              html
            }
            image(locales: pt) {
              handle
              width
              height
              alt
              url
              fileName
            }
          }
        }
        titleCreation
        contactForm {
          title
          content
          nameField
          surnameField
          companyField
          emailField
          phoneField
          serviceOptionDefault
          serviceOption1
          serviceOption2
          serviceOption3
          serviceOption4
          aboutYouField
          budgetDefault
          budgetOption1
          budgetOption2
          budgetOption3
          sendButtonText
          formErrorMessage
          privacyPolicyTitle
          privacyPolicyText
          thankYouTitle
          thankYouContent
          backToSiteText
          toBlogText
        }
      }
      testimonials(locales: $locale) {
        id
        logo(locales: pt) {
          handle
          width
          height
          alt
          url
          fileName
          title
        }
        author
        role
        description
        company
        showOnHome
      }
      headerMenu: menu(where: { title: "header" }, locales: $locale) {
        locale
        callToAction
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
          subMenuItems {
            title
            slug
            locale
            goTo
            externalLink
          }
        }
      }
      footerMenu: menu(where: { title: "footer" }, locales: $locale) {
        menuItems {
          title
          slug
          locale
          goTo
          externalLink
        }
      }
      awards(where: { showOnPage_contains_some: Home }) {
        image(locales: pt) {
          alt
          url
        }
      }
      numbers(locales: $locale) {
        title
        description
      }
      floatingActionButtons(locales: $locale) {
        title
      }
      technologies(locales: $locale) {
        name
        image(locales: pt) {
          alt
          url
        }
      }
      packages(locales: $locale) {
        title
        subtitle
        description
      }
      servicesV23(
        where: { showInPage_contains_some: [Home] }
        locales: $locale
      ) {
        altIcon {
          url
        }
        altCallToAction
        altDescription
        title
        slug
        alternativeSlug
      }
      customers(where: { showOnPage: Home }) {
        id
        name
        website
        logo {
          alt
          title
          url
        }
      }
    }
  }
`;

export default HomePage;
