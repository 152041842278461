import React from 'react';
import Helmet from 'react-helmet';
import Hero from 'templates/Home/Hero';
import Services from 'components/Services';
import About from 'templates/Home/Hero/About';
import Testimonials from 'templates/Home/Testimonials';
import PartnersSwiper from 'components/PartnersSwiper';
import ContactUs from 'components/ContactUs';
import ContactUsCTA from 'components/ContactUsCTA';
import Awards from 'components/Awards';
import RelevantNumbers from 'components/RelevantNumbers';
import Technologies from 'components/Technologies';
import Packages from 'components/Packages';
import SoftwareAtelier from './Hero/SoftwareAtelier';
import BubbleButtonWithModal from 'components/BubbleButtonWithModal';
import IconSparkling from 'assets/images/sparkling-fill.svg';

const Home = ({
  page,
  testimonials,
  pageContext,
  menu,
  awards,
  numbers,
  technologies,
  packages,
  services,
  customers,
  chatDescription
}) => {
  const {
    contactForm,
    locale,
    callToAction3,
    content4Subtitle,
    content4Title,
    contentSubtitle
  } = page;

  const { baseURL } = pageContext;
  const { callToAction } = menu;
  const { content8Subtitle } = page;

  return (
    <React.Fragment>
      <Hero>
        <SoftwareAtelier page={page} baseURL={baseURL} />
      </Hero>
      <PartnersSwiper page={page} customers={customers} />
      <ContactUsCTA
        title={callToAction3}
        pageContext={pageContext}
        buttonText={callToAction}
      />
      <Hero>
        <Packages
          page={page}
          packages={packages}
          showSeeMore={locale == 'pt' ? 'Ver mais →' : 'See more →'}
        />
      </Hero>
      <Hero>
        <Services
          contentSubtitle={contentSubtitle}
          baseURL={baseURL}
          services={services}
          pageContext={pageContext}
        />
      </Hero>
      <Hero>
        <Technologies
          contentSubtitle={content4Subtitle}
          contentTitle={content4Title}
          technologies={technologies}
        />
      </Hero>
      <ContactUsCTA
        title={callToAction3}
        pageContext={pageContext}
        buttonText={callToAction}
      />
      <Hero>
        <About page={page} baseURL={baseURL} />
      </Hero>
      <Hero>
        <RelevantNumbers
          contentSubtitle={content8Subtitle}
          numbers={numbers}
        />
      </Hero>
      <Awards awards={awards} />
      <Testimonials testimonials={testimonials} />
      <ContactUs
        contactForm={contactForm}
        baseURL={baseURL}
        lang={locale}
        styledAsLp={true}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context":"https://schema.org",
              "@type":"Organization",
              "name":"ateliware",
              "legalName":"ateliware",
              "url":"http://ateliware.com/",
              "logo":"https://media.graphassets.com/gJsd71c5SGh1RyV8tIrV",
              "address":{
                "@type":"PostalAddress",
                "streetAddress":"Rua Pasteur, 463",
                "addressLocality":"Curitiba",
                "addressRegion":"Paraná",
                "postalCode":"80250-080",
                "addressCountry":"Brasil"
              },
              "contactPoint":{
                "@type":"ContactPoint",
                "contactType":"Contato",
                "telephone":"[+55 41 3010-2275]",
                "email":"hello@ateliware.com"
              },
              "sameAs":[
                "https://github.com/ateliware",
                "https://www.linkedin.com/company/ateliware/",
                "https://twitter.com/ateliware",
                "https://www.youtube.com/channel/UCxPjijyar2emdKLPpOrugOw",
                "https://www.meetup.com/ateliware/",
                "https://goo.gl/maps/gLPc35mF3js7rLnL8"
              ]
            }
          `}
        </script>
      </Helmet>
      <BubbleButtonWithModal
        url="https://chat.ateliware.com"
        logoSrc={IconSparkling}
        description={chatDescription}
      />
    </React.Fragment>
  );
};

export default Home;
