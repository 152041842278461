import React from 'react';
import { StyledWrapper } from 'assets/global/styled';
import { StyledHero, StyledHeroContainer } from './styled';

const Hero = ({ children, ...props }) => {
  const { layout } = props;

  return (
    <StyledHero>
      <StyledWrapper>
        <StyledHeroContainer layout={layout}>
          {children}
        </StyledHeroContainer>
      </StyledWrapper>
    </StyledHero>
  );
};

Hero.defaultProps = {
  layout: 'row',
  children: null
};

export default Hero;
