import React, { useState, useEffect } from 'react';

const BubbleButtonWithModal = ({ url, logoSrc, description }) => {
  const [isModalOpen, setModalOpen] = useState(true);
  const [timer, setTimer] = useState(null);

  const handleButtonClick = () => {
    if (isModalOpen) {
      setModalOpen(false);
      if (timer) clearTimeout(timer);
    } else {
      setModalOpen(true);
      const newTimer = setTimeout(() => {
        setModalOpen(false);
      }, 5000);
      setTimer(newTimer);
    }
  };

  const handleModalClick = () => {
    window.open(url, '_blank');
  };

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  return (
    <div style={combinedStyles.container}>
      <button
        onClick={handleButtonClick}
        onMouseEnter={(e) =>
          (e.currentTarget.style.transform = 'scale(1.1)')
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.transform = 'scale(1)')
        }
        style={combinedStyles.bubbleButton}
      >
        <img src={logoSrc} alt="Logo" style={combinedStyles.logo} />
      </button>
      <button
        onClick={handleModalClick}
        style={{
          ...combinedStyles.modal,
          opacity: isModalOpen ? 1 : 0,
          transform: isModalOpen
            ? 'translateX(0)'
            : 'translateX(20px)'
        }}
      >
        <span
          style={combinedStyles.modalText}
          role="img"
          aria-label={description}
        >
          {description}
        </span>
      </button>
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000
  },
  bubbleButton: {
    backgroundColor: '#ffffff',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
    transition: 'transform 0.3s ease',
    zIndex: 1001
  },
  logo: {
    width: '60%',
    height: '60%',
    filter: 'invert(0%)'
  },
  modal: {
    position: 'absolute',
    right: '80px',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '8px 12px',
    boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    maxWidth: '90vw',
    height: 'auto',
    cursor: 'pointer',
    transition: 'opacity 0.3s ease, transform 0.3s ease',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    zIndex: 1000
  },
  modalText: {
    fontSize: '12px',
    textAlign: 'left'
  }
};

const mediaStyles = {
  '@media (max-width: 600px)': {
    container: {
      bottom: '10px',
      right: '10px'
    },
    bubbleButton: {
      width: '50px',
      height: '50px'
    },
    logo: {
      width: '50%',
      height: '50%'
    },
    modal: {
      width: '200px',
      height: 'auto',
      right: '60px'
    }
  },
  '@media (max-width: 400px)': {
    bubbleButton: {
      width: '40px',
      height: '40px'
    },
    logo: {
      width: '40%',
      height: '40%'
    },
    modal: {
      width: '160px',
      height: 'auto',
      right: '50px'
    }
  }
};

const combinedStyles = {
  ...styles,
  ...mediaStyles
};

export default BubbleButtonWithModal;
