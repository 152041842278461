import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { isBrowser } from 'react-device-detect';
import {
  StyledHeroContent,
  StyledHeroMainTitle,
  StyledHeroSubtitle,
  StyledHeroImage
} from '../styled';
import GraphImg from 'graphcms-image';

const SoftwareAtelier = ({ page }) => {
  const {
    content3Title,
    content3Subtitle,
    content3,
    content3Image
  } = page;
  return (
    <React.Fragment>
      <StyledHeroContent>
        <StyledHeroSubtitle>{content3Subtitle}</StyledHeroSubtitle>
        <StyledHeroMainTitle>{content3Title}</StyledHeroMainTitle>
        <MarkdownPreview source={content3} />
      </StyledHeroContent>
      {isBrowser && content3Image?.url && (
        <StyledHeroImage>
          <GraphImg
            baseURI="https://media.graphassets.com"
            image={content3Image}
            maxWidth={700}
            alt={content3Image.alt}
            fit="clip"
          />
        </StyledHeroImage>
      )}
    </React.Fragment>
  );
};

export default SoftwareAtelier;
