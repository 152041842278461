import React from 'react';
import MarkdownPreview from '@uiw/react-markdown-preview';
import { isBrowser } from 'react-device-detect';
import {
  StyledHeroContent,
  StyledHeroTitle,
  StyledHeroSubtitle,
  StyledHeroImage
} from '../styled';
import GraphImg from 'graphcms-image';

const Cases = ({ page }) => {
  const {
    content6Title,
    content6Subtitle,
    content6,
    content6FeaturedImage
  } = page;

  return (
    <React.Fragment>
      <StyledHeroContent>
        <StyledHeroSubtitle>{content6Subtitle}</StyledHeroSubtitle>
        <StyledHeroTitle>{content6Title}</StyledHeroTitle>
        <MarkdownPreview source={content6} />
      </StyledHeroContent>
      {isBrowser && content6FeaturedImage?.url && (
        <StyledHeroImage>
          <GraphImg
            baseURI="https://media.graphassets.com"
            image={content6FeaturedImage}
            maxWidth={700}
            alt={content6FeaturedImage.alt}
            fit="clip"
          />
        </StyledHeroImage>
      )}
    </React.Fragment>
  );
};

export default Cases;
